import firebase from 'firebase/app';
/* global self, globalThis */
const log = require('debug')('mm-server:request');
const HTTP_VERBS = [ 'get', 'post', 'put', 'patch', 'delete' ];
const REQUEST_TIMEOUT = 30000;

/**
 * get a property from the global context
 * @param {string} property
 */
function getGlobal (property) {
  if (typeof self !== 'undefined' && self && property in self) return self[property];
  if (typeof window !== 'undefined' && window && property in window) return window[property];
  if (typeof global !== 'undefined' && global && property in global) return global[property];
  if (typeof globalThis !== 'undefined' && globalThis) return globalThis[property];
}

export class HTTPError extends Error {
  constructor (body, response) {
    super((body && body.message) || response.statusText);
    this.name = 'HTTPError';
    this.response = response;
    this.status = response.status;
    if (body) {
      this.name = body.name;
      this.code = body.code;
    }
  }
}

export class TimeoutError extends Error {
  constructor () {
    super('Request timed out');
    this.name = 'TimeoutError';
  }
}

const localStorage = getGlobal('localStorage');
if (!localStorage) throw new Error('LocalStorage API not available');
const STORAGE_KEY = 'jwt';

export function getJWt () {
  return localStorage.getItem(STORAGE_KEY);
}

export function setJWT (jwt) {
  localStorage.setItem(STORAGE_KEY, jwt);
}

export function removeJWT (jwt) {
  localStorage.removeItem(STORAGE_KEY);
}

/**
 * @typedef {Object} RequestHandlerOpts
 * @property {string} method
 * @property {Object} body
 * @property {Object} headers
 * @property {Object} baseURI
 * @property {number} timeout
 * @property {string|Object} searchParams
 * @property {boolean} authenticate
 */

/**
 * @template T
 * @param {string} input
 * @param {RequestHandlerOpts} opts
 * @returns {Promise<T>}
 */
export async function request (input, opts) {
  // get fetch method
  const fetch = getGlobal('fetch');
  if (!fetch) throw new Error('Fetch API not available');

  // add defaults
  opts = Object.assign({
    method: 'get',
    timeout: REQUEST_TIMEOUT,
    credentials: 'same-origin'
  }, opts);

  // normalize method
  if (~HTTP_VERBS.indexOf(opts.method)) opts.method = opts.method.toUpperCase();

  // normalize uri
  opts.baseURI = String(opts.baseURI || process.env.VUE_APP_API_URI || '');
  if (!opts.baseURI.endsWith('/')) opts.baseURI += '/';
  input = String(input || '');
  if (opts.baseURI && input.startsWith('/')) throw new Error(`input must not begin with a slash when using baseURI`);
  input = opts.baseURI + input;

  // add searchParams
  if (opts.searchParams) {
    // convert query string (for POJOs and arrays)
    if (typeof opts.searchParams !== 'string') opts.searchParams = new URLSearchParams(opts.searchParams).toString();
    input += `?${opts.searchParams}`;
  }

  // addd headers
  opts.headers = opts.headers || {};
  opts.headers['Content-Type'] = 'application/json';

  // attach authorization header
  if (firebase.auth().currentUser) {
    const jwt = await firebase.auth().currentUser.getIdToken();
    if (jwt) opts.headers['Authorization'] = `Bearer ${jwt}`;
  }

  // normalize body (for POJOs and arrays)
  if (typeof opts.searchParams !== 'string') opts.body = JSON.stringify(opts.body);

  // build opts
  const fetchopts = {
    body: opts.body,
    method: opts.method,
    headers: opts.headers,
    credentials: opts.credentials
  };
  log(`[REQ]`, { url: input, ...fetchopts });

  return Promise
    .race([
      // execute REST call
      fetch(input, fetchopts),
      // race a timeout
      new Promise((resolve, reject) => setTimeout(() => reject(new TimeoutError()), opts.timeout))
    ])
    // parse result
    .then(response => response.text().then(body => {
      try {
        body = body === '' ? null : JSON.parse(body);
        log(`[RES]`, {
          body,
          ok: response.ok,
          url: response.url,
          type: response.type,
          status: response.status,
          redirected: response.redirected
        });
      } catch (error) {
        log(`[RES#body-parse-error]`, body);
        throw error;
      }
      if (response.status < 300) return body;
      throw new HTTPError(body, response);
    }))
    // log errors
    .catch(error => {
      log(`[ERR]`, error);
      throw error;
    });
}
