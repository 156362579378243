<template lang="pug">
  v-overlay(:value="model")
    v-progress-circular(indeterminate size="64")
</template>

<script>
export default {
  computed: {
    model () {
      return this.$store.state.ui.loading;
    },
    modelTitle () {
      return this.$store.state.ui.loadingMessage.title;
    },
    modelBody () {
      return this.$store.state.ui.loadingMessage.body;
    }
  }
};
</script>
