import _ from 'lodash';
import Vue from 'vue';
import store from './store';
import { logger } from '@/utils/logger';

const log = logger('ui');

/**
 * @typedef {Object} SnackConfig
 * @property {string} message
 * @property {string} color
 * @property {function} closeCb
 */

/**
 * @typedef {Object} LoaderConfig
 * @property {boolean} loading
 * @property {Object} message
 * @property {Object} message.title
 * @property {Object} message.body
 */

store.registerModule('ui', {
  namespaced: true,
  state: {
    /** @type {SnackConfig[]} */
    snacksQueue: [],
    loading: false,
    loadingMessage: {
      title: '',
      body: ''
    }
  },
  mutations: {
    setSnacksQueue (state, obj) {
      state.snacksQueue = obj || [];
    },
    setLoading (state, obj) {
      const { loading, message } = obj || {};
      state.loading = loading || false;
      state.loadingMessage = Object.assign({
        title: '',
        body: ''
      }, _.pick(message, 'title', 'body'));
    }
  },
  actions: {
    /**
     * @param ctx
     * @param {Error|string|SnackConfig} opts
     */
    enqueueSnack (ctx, opts) {
      if (opts instanceof Error) log('enqueueSnack#opts ', opts);
      const config = Object.assign(
        {
          color: 'success',
          timeout: 3000
        },
        opts instanceof Error
          ? { message: opts.message, color: 'error' }
          : typeof opts === 'string' ? { message: opts } : opts
      );
      const queue = ctx.state.snacksQueue;
      ctx.commit('setSnacksQueue', queue.concat(config));
      return config;
    },
    dequeueSnack (ctx, opts) {
      const queue = ctx.state.snacksQueue;
      if (!queue.length) return;
      const config = queue[0];
      ctx.commit('setSnacksQueue', _.drop(queue));
      return config;
    },
    /**
     * @param ctx
     * @param {boolean|string|LoaderConfig} opts
     */
    setLoading (ctx, opts) {
      if (opts == null) throw new Error(`Invalid loading options`);
      if (typeof opts === 'boolean') opts = { loading: opts };
      if (typeof opts === 'string') opts = { loading: true, message: { message: opts } };
      ctx.commit('setLoading', opts);
    }
  }
});

Vue.mixin({
  methods: {
    $enqueueSnack (opts) {
      return store.dispatch('ui/enqueueSnack', opts);
    },
    $setLoading (opts) {
      return store.dispatch('ui/setLoading', opts);
    }
  }
});
