export const menus = [
  {
    text: 'Home',
    path: '/',
    action: 'home'
  },
  {
    text: 'Be a Hero',
    path: '/be-a-hero',
    action: 'beAHero'
  },
  {
    text: 'Volunteer',
    path: '/volunteer',
    action: 'volunteer'
  },
  {
    text: 'Who We Are',
    path: '/about',
    action: 'about'
  }
];
