import { chunk } from 'lodash';

export const commafy = (n) => {
  let x = n.toString();
  let decimalPart = '';
  if (x.includes('.')) {
    let split = x.split('.');
    x = split[0];
    decimalPart = split[1];
  }
  let y = x.split('').reverse();
  let z = chunk(y, 3);
  let ans = [];

  z.forEach((e, i, a) => {
    e.forEach((ee, ii, aa) => {
      ans.push(aa[ii]);
    });

    if (i < a.length - 1) { ans.push(','); }
  });

  let final = ans.reverse().join('');
  if (decimalPart) {
    final = `${final}.${decimalPart.substring(0, 2)}`;
  }
  return final;
};

export const prettifyFirst = ({ name = {} }) => {
  return `${name.firstName} ${name.lastName}`;
};
