<template lang="pug">
  v-footer( 
    padless 
    dark
    color="#707175"
  ).ma-0.pa-0
    
    v-img(
      :src="bg"
      alt="Footer background"
    ).mx-0.mb-0.mt-n1.pa-0
    v-col(cols="12")
      v-row(
        align-content="end"
        align="end"
        justify="center"
      )
        img(
          src="@/assets/mmi-home-icon-footer-logo.png"
          alt="Logo"
        ).pb-6
    v-col(cols="12")
      v-row(
        align-content="end"
        align="end"
        justify="center"
      )
        div.mb-5
          div(v-if="$isMobileSmallAndDown").text-center
            template(v-for="(menu, i) in menus")
              router-link(
                :key="i"
                :to="menu.path"
                :title="`Mighty Minds ${menu.text}`"
                @click="menu.action"
              ).fontsize-14.header-item.white--text {{ menu.text }}
              br(v-if="i < menus.length")
              br(v-if="i < menus.length")
            a(
              v-if="!$currentUser"
              title="Mighty Minds Login"
              @click="handleLoginLink"
            ).text-none.header-item.white--text.fontsize-14 Login
            a(
              v-else
              title="Mighty Minds Signout"
              @click.stop="signout" 
            ).text-none.header-item.white--text.fontsize-14 Sign Out
          div(v-else)
            template(v-for="(menu, i) in menus")
              router-link(
                :key="i"
                :to="menu.path"
                :title="`Mighty Minds ${menu.text}`"
                @click="menu.action"
              ).fontsize-14.header-item.white--text {{ menu.text }}
              span(
                v-if="i < menus.length"
                :class="$isMediumAndDown ? 'mx-2' : 'mx-8'"
              ) |
            a(
              v-if="!$currentUser"
              title="Mighty Minds Login"
              @click="handleLoginLink"
            ).text-none.header-item.white--text.fontsize-14 Login
            a(
              v-else
              title="Mighty Minds Signout"
              @click.stop="signout"
            ).text-none.header-item.white--text.fontsize-14 Sign Out
      v-row(
        align-content="end"
        align="end"
        justify="center"
      ).pb-8
        div
          small.fontsize-14 Follow us on 
          a(
            @click.stop="open('fb')"
            title="Mighty Minds Facebook"
          ).text-none
            v-icon mdi-facebook-box
          a(
            @click.stop="open('ig')"
            title="Mighty Minds Instagram"
          ).text-none
            v-icon mdi-instagram
          a(
            @click.stop="open('twitter')"
            title="Mighty Minds Twitter"
          ).text-none
            v-icon mdi-twitter
          a(
            @click.stop="open('linkedin')"
            title="Mighty Minds LinkedIn"
          ).text-none
            v-icon mdi-linkedin
          a(
            href="mailto:mightymindsworld@gmail.com"
            title="Mighty Minds Email"
          ).text-none
            v-icon mdi-email
      v-row(
        align-content="end"
        align="end"
        justify="center"
      ).text-center
        small.pb-7 © 2001 - {{ new Date().getFullYear() }}. Mighty Minds. All Rights Reserved.
    v-dialog(
      v-model="dialogSignin"
      v-if="dialogSignin"
      @click:outside="dialogSignin = false"
      width="500"
    )
      signin(@close="dialogSignin = false")
</template>

<script>
import Signin from './Signin';
import _ from 'lodash';
import * as constants from './navigation.constants';
import bgMobile from '@/assets/mmi-home-footer-background-mobile.png';
import bgLarge from '@/assets/mmi-home-footer-background.png';

export default {
  components: {
    Signin
  },
  data: () => ({
    menuList: constants.menus,
    dialogSignin: false,
    bgMobile,
    bgLarge,
    linksMap: {
      fb: 'https://www.facebook.com/MightyMinds',
      ig: 'https://www.instagram.com/mightymindsorg',
      linkedin: 'https://www.linkedin.com/company/mightymindsorg',
      twitter: 'https://twitter.com/MightyMindsOrg'
    }
  }),
  computed: {
    bg () {
      return this.$isMobileSmallAndDown ? bgMobile : bgLarge;
    },
    menus () {
      const vue = this;
      const actions = {
        home: function () {
          vue.$ga.event({
            eventCategory: 'link',
            eventAction: 'click-footer-home-link',
            eventLabel: 'footer-home-link'
          });
        },
        beAHero: function () {
          vue.$ga.event({
            eventCategory: 'link',
            eventAction: 'click-footer-BeAHero-link',
            eventLabel: 'footer-BeAHero-link'
          });
        },
        volunteer: function () {
          vue.$ga.event({
            eventCategory: 'link',
            eventAction: 'click-footer-volunteer-link',
            eventLabel: 'footer-volunteer-link'
          });
        },
        about: function () {
          vue.$ga.event({
            eventCategory: 'link',
            eventAction: 'click-footer-about-link',
            eventLabel: 'footer-about-link'
          });
        }
      };

      const menus = [];
      this.menuList.forEach(menu => {
        menus.push({
          text: menu.text,
          path: menu.path,
          action: _.get(actions, menu.action)
        });
      });

      return menus;
    }
  },
  methods: {
    async signout () {
      try {
        this.$setLoading(true);
        await this.$store.dispatch('auth/signout');
        this.$enqueueSnack('Signout success!');
      } catch (e) {
        this.$enqueueSnack(e);
      } finally {
        this.$setLoading(false);
      }
    },
    open (type) {
      this.$ga.event({
        eventCategory: 'link',
        eventAction: `click-footer-${type}-link`,
        eventLabel: `footer-${type}-link`
      });
      window.open(this.linksMap[type], '_blank');
    },
    handleLoginLink () {
      this.dialogSignin = true;
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-footer-login-link',
        eventLabel: 'footer-login-link'
      });
    }
  }
};
</script>

<style scoped>
.header-item {
  text-decoration: none;
}

a {
  text-decoration: none;
}
</style>
