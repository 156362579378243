<template lang="pug">
  div.snackbar
</template>

<script>
import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted);

export default {
  computed: {
    queue () {
      return this.$store.state.ui.snacksQueue;
    }
  },
  watch: {
    queue () {
      this.displaySnack();
    }
  },
  methods: {
    async displaySnack () {
      const config = await this.$store.dispatch('ui/dequeueSnack');
      if (!config) return;
      const {
        message,
        theme = 'toasted-primary',
        color = 'info',
        timeout = 3000,
        top,
        bottom,
        left,
        right,
        action
      } = config;

      const position = [ 'bottom', 'center' ];
      if (bottom) position[0] = 'bottom';
      if (top) position[0] = 'top';
      if (left) position[1] = 'left';
      if (right) position[1] = 'right';
      if (!left && !right) position[1] = 'center';

      this.$toasted.show(message, {
        theme,
        position: position.join('-'),
        duration: timeout,
        keepOnHover: true,
        iconPack: 'mdi',
        type: color,
        className: 'font',
        action
      });
    }
  }
};
</script>

<style>
.font {
  font-family: Poppins, sans-serif;
}
</style>