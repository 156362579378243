import Vue from 'vue';
import store from './store';
import { firebaseAuth, EmailAuthProvider } from '../plugins/firebase';
import * as utils from '../utils/request';
import { logger } from '../utils/logger';
import _ from 'lodash';

const log = logger('store:auth');

store.registerModule('auth', {
  namespaced: true,
  state: {
    currentUser: null,
    firebaseUser: null
  },
  mutations: {
    setCurrentUser (state, obj) {
      state.currentUser = obj || null;
    },
    setFirebaseUser (state, obj) {
      state.firebaseUser = obj || null;
    }
  },
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} opts.uid
     */
    async loadCurrentUser (ctx, opts) {
      if (!_.isEmpty(ctx.state.currentUser) && !opts.force) return ctx.state.currentUser;
      const currentUser = await utils.request(`accounts/${opts.uid}`, {
        method: 'get'
      });

      log('loadCurrentUser#currentUser', currentUser);
      log('loadCurrentUser#firebaseAuth.currentUser', firebaseAuth.currentUser);

      ctx.commit('setCurrentUser', currentUser);
      ctx.commit('setFirebaseUser', firebaseAuth.currentUser);
    },
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} obj.email
     * @param {string} obj.password
     */
    async signin (ctx, opts) {
      opts = Object.assign({}, opts);
      if (!opts.email) throw new Error(`Email is required`);
      if (!opts.password) throw new Error(`Password is required`);
      const { user } = await firebaseAuth.signInWithEmailAndPassword(opts.email, opts.password);

      const signinUser = await utils.request(`accounts/${user.uid}`, {
        method: 'get'
      });

      log('signin#user', user);
      log('signin#signinUser', signinUser);

      ctx.commit('setCurrentUser', signinUser);
      ctx.commit('setFirebaseUser', user);
    },
    async signout (ctx) {
      await firebaseAuth.signOut();
      ctx.commit('setCurrentUser');
      ctx.commit('setFirebaseUser');
    },
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} obj.firstName
     * @param {string} obj.lastName
     * @param {string} obj.mobileNo
     * @param {string} obj.email
     * @param {string} obj.password
     */
    async signup (ctx, opts) {
      const body = {
        name: {
          firstName: opts.firstName,
          lastName: opts.lastName
        },
        email: opts.email,
        password: opts.password
      };
      await utils.request(`accounts`, {
        method: 'post',
        body
      });

      await ctx.dispatch(
        'signin',
        {
          email: opts.email,
          password: opts.password
        }
      );

      await firebaseAuth.currentUser.sendEmailVerification();
    },
    /**
     * @param ctx
     * @param {string} oldPassword
     * @param {string} newPassword
     */
    async updatePassword ({ state }, { oldPassword, newPassword }) {
      if (!oldPassword) throw new Error('Old password cannot be empty.');
      if (!newPassword) throw new Error('New password cannot be empty.');

      const credential = EmailAuthProvider.credential(
        state.currentUser.email,
        oldPassword
      );
      await firebaseAuth.currentUser.reauthenticateWithCredential(credential);

      await firebaseAuth.currentUser.updatePassword(newPassword);
    },
    async resendVerification () {
      await firebaseAuth.currentUser.sendEmailVerification();
    }
  }
});

Vue.mixin({
  computed: {
    $currentUser () {
      return store.state.auth.currentUser;
    }
  }
});
