<template lang="pug">
  div
    div
      v-app-bar(
        app 
        elevate-on-scroll
        :class="$isMediumAndDown ? '' : 'header'"
      )
        template(v-if="$isMediumAndDown")
          img(
            src="@/assets/mmi-emblem.png" 
            height="27"
            alt="Emblem"
          )
          v-spacer
          v-app-bar-nav-icon(
            v-if="!$currentUser"
            @click.stop="navDrawer = !navDrawer"
          )
          v-app-bar-nav-icon(
            v-else
            @click.stop="navDrawer = !navDrawer"
          ).mr-0
            v-avatar
              v-img(
                v-if="$currentUser.picURL" 
                :src="$currentUser.picURL"
                :alt="alt"
              )
              v-img(
                v-else 
                src="@/assets/person-placeholder.png"
                alt="Profile placeholder"
              )
        template(v-else)
          router-link(
            to='/'
            title="Mighty Minds Home"
          ).mt-3
            img(
              src="@/assets/mmi-home-icon-header-logo.png" 
              alt="Logo icon"
            )

          v-spacer
          //- navigation
          v-toolbar-items(
            v-for="(menu, i) in [menus[1], menus[2], menus[3]]"
            :key="i"
          )
            v-btn(
              :to="menu.path"
              :title="`Mighty Minds ${menu.text}`"
              text
              @click="menu.action"
            ).text-none.accent--text.fontsize-12.opensans {{ menu.text }}
          v-toolbar-items(v-if="!$currentUser")
            v-btn(
              color="primary" 
              title="Mighty Minds Login"
              depressed
              :loading="loading"
              @click="dialogSignin = true"
            ).fontsize-18 Login
          v-toolbar-items(v-else)
            v-menu(offset-y left)
              template(#activator="{ on }")
                v-btn(
                  text 
                  v-on="on"
                  title="Mighty Minds Profile"
                )
                  v-avatar.mr-1
                    img(
                      v-if="$currentUser.picURL" 
                      :src="$currentUser.picURL"
                      :alt="alt"
                    )
                    img(
                      v-else 
                      src="@/assets/person-placeholder.png"
                      alt="Profile placeholder"
                    )
              v-list(width="300")
                v-list-item(three-line)
                  v-list-item-avatar
                    img(
                      v-if="$currentUser.picURL" 
                      :src="$currentUser.picURL"
                      :alt="alt"
                    )
                    img(
                      v-else 
                      src="@/assets/person-placeholder.png"
                      alt="Profile placeholder"
                    )
                  v-list-item-content
                    v-list-item-title {{ $currentUser | prettify-first }}
                    v-list-item-subtitle {{ $currentUser.email }}
                    v-list-item-subtitle 
                      v-chip(
                        v-if="firebaseUser.emailVerified" 
                        color="primary"
                        small
                      ) Verified
                      v-chip(
                        v-else 
                        color="error"
                        small
                      ) Unverified
                v-divider
                v-list-item(:to="{ name: 'benefactor', params: { uid: $currentUser.uid } }")
                  v-list-item-title
                    v-icon(style="font-size: 20px;").mr-2 mdi-account-outline
                    | My Account
                v-list-item(
                  @click.stop="signout"
                  title="Mighty Minds Signout"
                )
                  v-list-item-title.error--text
                    v-icon.fontsize-20.error--text.mr-2 mdi-logout-variant
                    | Sign out
                v-divider
                v-list-item
                  v-list-item-title
                    small.right App version: {{ VERSION }}
      v-navigation-drawer(
        app
        v-if="$isMediumAndDown"
        v-model="navDrawer"
        right
      )
        v-row(
          justify="center"
          no-gutters
        )
          img(
            src="@/assets/mmi-logo-02.png" 
            height="auto"
            alt="Logo"
          ).pt-6
        v-list
          v-list-item-group(
            v-for="(menu, i) in menus"
            :key="i"
          )
            v-list-item(
              :to="menu.path"
              @click="menu.action"
            )
              v-list-item-content
                v-list-item-title {{ menu.text }}
        v-row(
          v-if="!$currentUser"
          justify="center" 
        )
          v-btn(
            color="primary" 
            :loading="loading"
            @click="dialogSignin = true; navDrawer = false"
          ).fontsize-18 Login
        v-list(v-else)
          v-list-item(three-line)
            v-list-item-avatar
              img(
                v-if="$currentUser.picURL" 
                :src="$currentUser.picURL"
                :alt="alt"
              )
              img(
                v-else 
                src="@/assets/person-placeholder.png"
                alt="Profile placeholder"
              )
            v-list-item-content
              v-list-item-title {{ $currentUser | prettify-first }}
              v-list-item-subtitle {{ $currentUser.email }}
              v-list-item-subtitle 
                v-chip(
                  v-if="firebaseUser.emailVerified" 
                  color="primary"
                  small
                ) Verified
                v-chip(
                  v-else 
                  color="error"
                  small
                ) Unverified
          v-divider
          v-list-item(:to="{ name: 'benefactor', params: { uid: $currentUser.uid } }")
            v-list-item-title
              v-icon(style="font-size: 20px;").mr-2 mdi-account-outline
              | My Account
          v-list-item(@click.stop="signout")
            v-list-item-title.error--text
              v-icon.fontsize-20.error--text.mr-2 mdi-logout-variant
              | Sign out
          v-divider
          v-list-item
            v-list-item-title
              small.right App version: {{ VERSION }}
    //- FIXME: Bring back later, investigate what happened with IE
    //- cookie-law(
    //-   position="top" 
    //-   transitionName="fade"
    //-   :class="$isMediumAndDown ? 'mobile' : 'site'"
    //- ).Cookie.px-0.pb-1.z-index
    //-   div(slot-scope="props")
    //-     v-row(
    //-       :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
    //-       no-gutters
    //-       justify="space-between"
    //-     )
    //-       v-col(cols="11").white--text
    //-         | We use third-party services to understand web traffic data for us and they may collect cookies during the process. By continuing to browse our site, you agree to Mighty Mind's 
    //-         a(@click="handleTermsLink").font-weight-black.white--text.underline Terms of Use & Privacy Policy
    //-         | . Feel free to check out our policies anytime for more info.
    //-       v-col(
    //-         cols="1"
    //-       ).text-right.py-0
    //-         v-btn(
    //-           icon 
    //-           color="white" 
    //-           @click.stop="props.accept"
    //-           x-small
    //-         )
    //-           v-icon mdi-close
    terms-and-conditions-dialog(
      @close="termsDialog = false"
      :dialog="termsDialog"
    ).z-index-top
    v-dialog(
      v-model="dialogSignin"
      v-if="dialogSignin"
      @click:outside="dialogSignin = false"
      width="500"
    )
      signin(@close="dialogSignin = false")
</template>

<script>
import Signin from './Signin';
import * as constants from './navigation.constants';
import { firebaseAuth } from '../plugins/firebase';
import CookieLaw from 'vue-cookie-law';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import _ from 'lodash';

const VERSION = process.env.VUE_APP_VERSION || 'development';

export default {
  name: 'Toolbar',
  components: {
    Signin,
    CookieLaw,
    TermsAndConditionsDialog
  },
  data: () => ({
    VERSION,
    loading: false,
    dialogSignin: false,
    menuList: constants.menus,
    navDrawer: false,
    termsDialog: false
  }),
  created () {
    this.loadUser();
  },
  computed: {
    alt () {
      return this.$currentUser
        ? this.$currentUser.name.firstName.charAt(0) +
            this.$currentUser.name.lastName.charAt(0)
        : 'Profile placeholder';
    },
    menus () {
      const vue = this;
      const actions = {
        home: function () {
          vue.$ga.event({
            eventCategory: 'button',
            eventAction: 'click-appbar-home-btn',
            eventLabel: 'appbar-home-btn'
          });
        },
        beAHero: function () {
          vue.$ga.event({
            eventCategory: 'button',
            eventAction: 'click-appbar-BeAHero-btn',
            eventLabel: 'appbar-BeAHero-btn'
          });
        },
        volunteer: function () {
          vue.$ga.event({
            eventCategory: 'button',
            eventAction: 'click-appbar-volunteer-btn',
            eventLabel: 'appbar-volunteer-btn'
          });
        },
        about: function () {
          vue.$ga.event({
            eventCategory: 'button',
            eventAction: 'click-appbar-about-btn',
            eventLabel: 'appbar-about-btn'
          });
        }
      };

      const menus = [];
      this.menuList.forEach(menu => {
        menus.push({
          text: menu.text,
          path: menu.path,
          action: _.get(actions, menu.action)
        });
      });

      return menus;
    },
    firebaseUser () {
      return this.$store.state.auth.firebaseUser || {};
    }
  },
  methods: {
    async loadUser () {
      try {
        this.loading = true;
        firebaseAuth.onAuthStateChanged(async user => {
          if (user) {
            await this.$store.dispatch('auth/loadCurrentUser', {
              uid: user.uid
            });
          }
        });
      } catch (e) {
        this.$enqueueSnack(e);
      } finally {
        this.loading = false;
      }
    },
    async signout () {
      try {
        this.loading = true;
        await this.$store.dispatch('auth/signout');
        this.$enqueueSnack('Signout success!');
      } catch (e) {
        this.$enqueueSnack(e);
      } finally {
        this.navDrawer = false;
        this.loading = false;
      }
    },
    handleTermsLink () {
      this.termsDialog = true;
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-cookie-terms-link',
        eventLabel: 'cookie-terms-link'
      });
    }
  }
};
</script>

<style scoped>
.header {
  padding-right: 184px;
  padding-left: 184px;
}
.Cookie {
  background-color: #707276;
}
.mobile {
  margin-top: 56px;
}
.site {
  margin-top: 64px;
}
.underline {
  text-decoration: underline;
}
.z-index {
  z-index: 98;
}
.z-index-top {
  z-index: 99;
}
</style>
