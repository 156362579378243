import store from './store';
import * as utils from '../utils/request';

/**
 * @typedef {Object} Benefactor
 * @property {string} uid
 * @property {string} picURL
 * @property {Object} name
 * @property {string} name.firstName
 * @property {string} name.lastName
 */

store.registerModule('benefactors', {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} opts.uid
     * @param {boolean} opts.$forceFetch
     * @returns {Benefactor}
     */
    async get (ctx, opts) {
      opts = Object.assign({}, opts);
      if (!opts.uid) throw new Error(`Uid is required`);

      // fetch item/get from cache
      const item = await utils.request(`accounts/${opts.uid}`, { method: 'get' });

      if (!item) {
        let error = new Error('Benefactor does not exist');
        error.code = 'benefactor/not-found';
        throw error;
      }
      return item;
    }
  }
});
