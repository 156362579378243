import store from './store';
import * as utils from '../utils/request';

store.registerModule('accounts', {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     */
    async updateAccount (ctx, opts) {
      const { uid, name, picURL, location, aboutMe, occupation } = Object.assign({}, opts);
      const body = { name };

      if (picURL) body.picURL = picURL;
      if (location) body.location = location;
      if (aboutMe) body.aboutMe = aboutMe;
      if (occupation) body.occupation = occupation;

      await utils.request(`accounts/${uid}`, {
        method: 'put',
        body
      });
    }
  }
});
