import Vue from 'vue';

Vue.mixin({
  computed: {
    $isMediumAndDown () {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    $isMobileSmallAndDown () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    $isMobileSmall () {
      return this.$vuetify.breakpoint.smOnly;
    },
    $isMobileXSmall () {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
});
