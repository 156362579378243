<template lang="pug">
  v-card
    v-card-title.mb-3
      v-spacer
      img(
        src="@/assets/mmi-emblem.png"
        alt="Emblem"
      )
      v-spacer
    v-card-text.px-8
      v-form(
        ref="form"
        v-model="valid"
        @keydown.native.enter="signin()"
        v-if="!showForgotPassword"
      )
        v-text-field(
          v-model="email"
          label="Email Address"
          outlined
          :error="emailErrorShow"
          :error-messages="emailErrorMessage"
          :rules="emailRules"
        )
        v-text-field(
          v-model="password"
          label="Password"
          outlined
          :rules="passwordRules"
          :error="passwordErrorShow"
          :error-messages="passwordErrorMessage"
          :type="passwordVisible ? 'text' : 'password'"
          :append-icon="passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          @click:append="passwordVisible=!passwordVisible"
        )
        p.fontsize-16
          a(
            @click.stop="forgotPassword()"
            title="Mighty Minds Forgot Password"
          ) Forgot Password?
        p.fontsize-16.mb-0.grey--text.text--darken-1 No account yet?&nbsp
          a(
            @click.stop="signup"
            title="Mighty Minds Signup"
          ) Create an account
          span.grey--text.text--darken-1 .
      v-form(
        ref="resetForm"
        v-model="valid"
        @submit.prevent="valid && sendResetEmail()"
        v-if="showForgotPassword"
      )
        span.fontsize-18.black--text Reset your password
        br
        p.fontsize-14 Enter your email address and we'll email you a link to reset your password.
        v-text-field(
          v-model="emailForgot"
          label="Email Address"
          outlined
          :rules="emailRules"
        )
    v-card-actions.px-8
      v-spacer
      v-btn(
        v-if="!showForgotPassword"
        text
        color="secondary"
        x-large
        :disabled="loading"
        @click.stop="close"
      ) Cancel
      v-btn(
        v-else="showForgotPassword"
        text
        color="secondary"
        x-large
        :disabled="loading"
        @click.stop="showForgotPassword = false"
      ) Go Back
      v-btn(
        v-if="!showForgotPassword"
        color="primary"
        x-large
        :loading="loading"
        @click.stop="signin()"
      ) Login
      v-btn(
        v-else
        color="primary"
        x-large
        :loading="loading"
        @click.stop="sendResetEmail()"
      ) Send Email
</template>

<script>
import { firebaseAuth } from '../plugins/firebase';

export default {
  name: 'Signin',
  data: () => ({
    loading: false,
    valid: false,
    email: '',
    emailForgot: '',
    emailRules: [
      v => !!v || 'Email is required'
    ],
    emailErrorShow: false,
    emailErrorMessage: '',
    password: '',
    passwordVisible: false,
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    passwordErrorShow: false,
    passwordErrorMessage: '',
    showForgotPassword: false
  }),
  methods: {
    async signin () {
      try {
        this.loading = true;
        this.$refs.form.resetValidation();
        this.resetErrorMessages();
        this.$ga.event({
          eventCategory: 'button',
          eventAction: 'click-popup-login-btn',
          eventLabel: 'popup-login-btn'
        });

        await this.$store.dispatch('auth/signin', {
          email: this.email,
          password: this.password
        });

        this.$enqueueSnack('Signin Success!');
        this.$emit('close');
      } catch (error) {
        const errorCode = error.code;

        switch (errorCode) {
          case 'auth/wrong-password': {
            this.passwordErrorShow = true;
            this.passwordErrorMessage = 'Incorrect password';
            this.$enqueueSnack(new Error('Entered password is incorrect.'));
            break;
          }
          case 'auth/too-many-requests': {
            this.passwordErrorShow = true;
            this.passwordErrorMessage = 'Incorrect password';
            this.$enqueueSnack(new Error('Too many unsuccessful login attempts. Please try again later.'));
            break;
          }
          case 'auth/invalid-email':
          case 'auth/user-not-found': {
            this.emailErrorShow = true;
            this.emailErrorMessage = 'Incorrect email';
            this.passwordErrorShow = true;
            this.passwordErrorMessage = 'Incorrect password';
            this.$enqueueSnack(new Error('User does not exist. Sign up for an account.'));
            break;
          }
          case 'auth/user-disabled': {
            this.emailErrorShow = true;
            this.passwordErrorShow = true;
            this.$enqueueSnack(
              new Error('The email or phone number you’ve entered doesn’t match any account. Sign up for an account.')
            );
            break;
          }
          default: {
            this.$enqueueSnack(error);
            break;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    signup () {
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-popup-signup-link',
        eventLabel: 'popup-signup-link'
      });
      this.$router.push('/signup');
      this.$emit('close');
    },
    forgotPassword () {
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-popup-forgot-password-link',
        eventLabel: 'popup-forgot-password-link'
      });
      this.showForgotPassword = true;
    },
    async sendResetEmail () {
      try {
        this.loading = true;
        this.$ga.event({
          eventCategory: 'button',
          eventAction: 'click-popup-reset-password-btn',
          eventLabel: 'popup-reset-password-btn'
        });

        await firebaseAuth.sendPasswordResetEmail(this.emailForgot);

        this.$enqueueSnack('Success! Please check your email to reset your password.');
        this.close();
      } catch (error) {
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/user-not-found':
          case 'auth/invalid-email': {
            this.$enqueueSnack(new Error('User does not exist. Sign up for an account.'));
            break;
          }
          default: {
            this.$enqueueSnack(error);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    close () {
      if (this.showForgotPassword) {
        this.$refs.resetForm.reset();
        this.$refs.resetForm.resetValidation();
      } else {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.email = '';
      this.emailForgot = '';
      this.password = '';
      this.showForgotPassword = false;
      this.$emit('close');
    },
    resetErrorMessages () {
      this.emailErrorShow = false;
      this.emailErrorMessage = '';
      this.passwordErrorShow = false;
      this.passwordErrorMessage = '';
    }
  }
};
</script>
