<template lang="pug">
  v-row(justify="center")
    template(v-if="loading")
      v-col(
        cols="12"
        sm="4"
        md="3"
        v-for="(v,i) in number" 
        :key="i"
      )
        v-card
          v-skeleton-loader(type="image, list-item-three-line")
    template(v-else).red
      v-col( 
        cols="12"
        sm="4"
        md="3"
        v-for="(beneficiary,i) in randomBeneficiaries" 
        :key="i"
      )
        beneficiary-card(:beneficiary="beneficiary")
</template>

<script>
import { sampleSize } from 'lodash';
import BeneficiaryCard from './BeneficiaryCard';
import { logger } from '../utils/logger';

const log = logger('beneficiary-list');

export default {
  components: {
    BeneficiaryCard
  },
  props: {
    number: {
      type: Number,
      default: 10
    },
    skip: String,
    ids: Array
  },
  data: () => ({
    loading: false,
    beneficiaries: []
  }),
  created () {
    this.loadData();
  },
  computed: {
    randomBeneficiaries () {
      const beneficiaries = this.beneficiaries;
      return sampleSize(beneficiaries, this.number);
    }
  },
  methods: {
    async loadData () {
      this.loading = true;
      try {
        const opts = {
          $limit: this.number,
          removeId: this.skip
        };
        // fetch random n beneficiary list
        if (this.ids) {
          const promises = this.ids.map(id => this.$store.dispatch('beneficiaries/getBeneficiary', {
            id,
            fetchTimeline: false
          }));
          this.beneficiaries = await Promise.all(promises);
          log('loadData#beneficiaries', this.beneficiaries);
        } else {
          this.beneficiaries = await this.$store.dispatch('beneficiaries/findBeneficiaries', opts);
        }
      } catch (error) {
        this.$enqueueSnack(error);
      }
      this.loading = false;
    }
  }
};
</script>
