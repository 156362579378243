<template lang="pug">
  div
    //- title panel
    v-container(
      fluid
      fill-height
    ).pa-0.ma-0.titlePanel
      v-layout(:class="$isMediumAndDown ? 'bgMobile' : 'bg'")
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        )
          v-col(
            cols="12"
            v-if="$isMediumAndDown"
          ).styleMobile.mt-7
            h1.fontsize-30.font-weight-black.accent--text Even the little
              br
              | acts of caring
              br
              | are enough to
              br
              | make you a hero.
            v-btn(
              :to="{name: 'beAHero'}"
              title="Mighty Minds Support"
              color="primary"
              @click="handleLoginBtn"
            ).fontsize-24.font-weight-medium.text-none.py-8.px-3 Support
          v-col(
            cols="12"
            v-else
          ).styleLarge.mt-110
            div.text-left
              h1.fontsize-48.font-weight-black.accent--text.mb-0 Even the little acts
                br
                | of caring are enough
                br
                | to make you a hero.
              v-btn(
                :to="{name: 'beAHero'}"
                color="primary"
                title="Mighty Minds Support"
              ).x-large.fontsize-24.mt-5.font-weight-medium.py-9.text-none Support A Student
    //- next panel
    v-container(fluid).pa-0
      //- panel 1
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).my-5
        v-col(cols="12").mt-5.mb-12.text-center
          span(:class="$isMediumAndDown ? 'fontsize-32' : 'fontsize-48'").font-weight-black.accent--text Your turn to save the day!
          h2.font-weight-medium.mb-0.opensans.fontsize-20.secondary--text.mb-6 Below are the students who need your support,
            br
            strong who do you want to help today?
          beneficiary-list(:number="20")
      //- panel 2
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).my-5
        v-col(
          cols="12"
          sm="8"
          md="6"
          align-self="center"
          :class="$isMobileSmallAndDown ? '' : 'pr-4'"
        )
          v-img(
            src="@/assets/mmi-home-graduate.png"
            alt="Graduate"
          )
        v-col(
          cols="12"
          sm="12"
          md="6"
          align-self="center"
          :class="$isMobileSmallAndDown ? 'text-center' : 'pl-4'"
        )
          span(:class="$isMediumAndDown ? 'fontsize-32' : 'fontsize-48'").font-weight-black.accent--text Education is a right!
          div(:class="$isMobileSmallAndDown ? 'text-center' : 'text-justify'")
            h2.mb-4.font-weight-medium.fontsize-20.secondary--text Many students and parents do not have the money for school.
            h2.font-weight-medium.fontsize-20.secondary--text Mighty Minds gives 100% of your donation to the student of your choice through our partner schools or organizations where the student is affiliated with.
            v-btn(
              :to="{name: 'beAHero'}"
              :class="$isMediumAndDown ? 'small' : 'x-large'"
              color="primary"
              title="Mighty Minds Support"
              @click="handleLoginBtn"
            ).mt-5.fontsize-24.text-none.py-9 Support A Student

      //- line divider
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).my-12
        div.mx-auto.text-center
          v-divider(color="primary").width-20vh

      //- panel 3
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        justify="center"
      ).mt-5.mb-100
        v-col(
          cols="12"
          sm="12"
          md="6"
          order="2"
          order-md="1"
          align-self="center"
          :class="$isMobileSmallAndDown ? 'text-center' : 'pr-4'"
        )
          span(:class="$isMediumAndDown ? 'fontsize-32' : 'fontsize-48'").font-weight-black.accent--text OHANA
          div(:class="$isMobileSmallAndDown ? 'text-center' : 'text-justify'")
            h2.font-weight-medium.fontsize-20.secondary--text OHANA means family. Family means nobody gets left behind or forgotten. This is our mighty vision. Mighty Minds serves as a platform to connect heroes to organizations who wish to raise funds for students who seek education and finish school.
            v-btn(
              href="http://bit.ly/MightyMindsPartnership"
              target="_blank"
              rel="noopener noreferrer"
              :class="$isMediumAndDown ? 'small' : 'x-large'"
              color="primary"
              title="Mighty Minds Support"
            ).mt-5.fontsize-24.text-none.py-9 Be A Partner
        v-col(
          cols="12"
          sm="8"
          md="6"
          align-self="center"
          order="1"
          order-md="2"
          :class="$isMobileSmallAndDown ? '' : 'pl-4'"
        )
          v-img(
            src="@/assets/mmi-home-hand-shake-connect.png"
            alt="Helping hand"
          )

</template>

<script>
import BeneficiaryList from '../components/BeneficiaryList';

export default {
  components: {
    BeneficiaryList
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  methods: {
    handleLoginBtn () {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click-home-support-btn',
        eventLabel: 'home-support-btn'
      });
    }
  }
};
</script>

<style scoped>
.bg {
  background-image: url('../assets/mmi-home-usp-hero-image-graduate.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.bgMobile {
  background-image: url('../assets/mmi-graduation-cap-diploma.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.mt-110 {
  margin-top: 110px;
}
.mb-100 {
  margin-bottom: 100px;
}
.width-20vh {
  width: 20vh;
}
</style>
