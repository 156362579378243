import Vue from 'vue';
import store from './store';
import * as utils from '../utils/request';
import _ from 'lodash';

/**
 * @typedef {Object} Benefactor
 * @property {string} uid
 * @property {string} picURL
 * @property {Object} name
 * @property {string} name.firstName
 * @property {string} name.lastName
 */

/**
 * @typedef {Object} Donation
 * @property {string} id
 * @property {string} message
 * @property {number} amount
 * @property {Benefactor} benefactor
 */

/**
 * @typedef {Object} FundingBreakdown
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {number} cost
 */

/**
 * @typedef {Object} Funding
 * @property {number} raised
 * @property {number} total
 * @property {FundingBreakdown[]} breakdown
 */

/**
 * @typedef {Object} Organization
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {string} picURL
 */

/**
 * @typedef {Object} TimelineItem
 * @property {string} id
 * @property {string} body
 * @property {string} title
 * @property {number} date
 */

/**
 * @typedef {Object} Beneficiary
 * @property {string} id
 * @property {string} picURL
 * @property {Object} name
 * @property {string} name.firstName
 * @property {string} name.lastName
 * @property {string} ambition
 * @property {string} storyShort
 * @property {string} storyLong
 * @property {string} quote
 * @property {Funding} activeFundingObject
 * @property {Benefactor[]} benefactors[]
 * @property {Donation[]} donations
 * @property {Organization} organization
 * @property {TimelineItem[]} timeline
 */

store.registerModule('beneficiaries', {
  namespaced: true,
  state: {
    /** @type {Beneficiary[]} */
    beneficiaries: []
  },
  mutations: {
    /**
     * @param state
     * @param {Object} opts
     * @param {Beneficiary[]} opts.items
     * @param {boolean} opts.upsert
     * @param {boolean} opts.remove
     */
    updateInBeneficiaries (state, opts) {
      const { items, upsert, remove } = opts;
      const list = state.beneficiaries;
      items.forEach(item => {
        const index = list.findIndex(b => b.id === item.id);
        if (~index) {
          if (remove) {
            Vue.delete(list, index, item);
          } else {
            Vue.set(list, index, item);
          }
          return;
        }

        if (upsert) {
          list.push(item);
        }
      });
    }
  },
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     * @param {number} opts.$limit
     * @param {string} opts.$skip
     * @param {string} opts.id
     * @param {string} opts.removeId
     * @returns {Beneficiary[]}
     */
    async findBeneficiaries (ctx, opts) {
      opts = Object.assign({ $limit: 50 }, opts);

      const items = await utils.request('beneficiaries', {
        method: 'get'
        // searchParams: opts
      });

      _.remove(items, item => !item.published);

      ctx.commit('updateInBeneficiaries', {
        items,
        upsert: true
      });

      return _.filter(items, item => item.id !== opts.removeId);
    },

    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} opts.id
     * @param {boolean} opts.$forceFetch
     * @param {boolean} opts.fetchTimeline
     * @returns {Beneficiary}
     */
    async getBeneficiary (ctx, opts) {
      opts = Object.assign({ fetchTimeline: true }, opts);
      if (!opts.id) throw new Error(`Id is required`);

      // check cache
      const cache = ctx.state.beneficiaries.find(b => b.id === opts.id);
      if (cache && !opts.$forceFetch) return cache;

      // fetch item/get from cache
      const item = cache || await utils.request(`beneficiaries/${opts.id}`, {
        method: 'get'
      });

      if (_.isEmpty(item)) {
        let error = new Error('Beneficiary does not exist');
        error.code = 'beneficiary/not-found';
        throw error;
      }

      if (opts.fetchTimeline) {
        item.timeline = await store.dispatch('timeline/get', { beneficiary: opts.id });
      }

      ctx.commit('updateInBeneficiaries', {
        items: [ item ],
        upsert: true
      });
      return item;
    },
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} opts.id
     * @param {string} opts.activeFunding
     * @param {boolean} opts.$publish
     */
    async updateFunding (ctx, opts) {
      const body = { ..._.omit(opts, 'id') };

      const item = await utils.request(
        `beneficiaries/${opts.id}`,
        {
          method: 'put',
          body
        }
      );

      ctx.commit(
        'updateInBeneficiaries',
        {
          items: [ item ],
          remove: true
        }
      );

      return item;
    }
  }
});
