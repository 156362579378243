import store from './store';
import * as utils from '../utils/request';

store.registerModule('funding', {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     */
    async getFunding (ctx, opts) {
      const item = await utils.request(`fundings/${opts.id}`, {
        method: 'get'
      });
      return item;
    },
    /**
     * @param ctx
     * @param {Object} opts
     * @param {string} opts.id
     * @param {string} opts.beneficiary
     * @param {string} opts.$resolve
     */
    async resolve (ctx, opts) {
      await utils.request(
        `fundings/${opts.id}`,
        {
          method: 'put',
          body: {
            $resolve: opts.$resolve
          }
        }
      );

      // const activeFunding = opts.$resolve ? '' : opts.id;

      await store.dispatch(
        'beneficiaries/updateFunding',
        {
          id: opts.beneficiary,
          // activeFunding,
          $publish: false
        }
      );
    }
  }
});
