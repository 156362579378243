import store from './store';
import * as utils from '../utils/request';

store.registerModule('donation', {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * @param ctx
     * @param {Object} opts
     */
    async makeDonation (ctx, opts) {
      const body = opts;
      const result = await utils.request('donations', {
        method: 'post',
        body
      });
      return result;
    },
    /**
     * @param ctx
     * @param {Object} opts
     */
    async getByParams (ctx, opts) {
      const result = await utils.request(
        'donations',
        {
          method: 'get',
          searchParams: opts
        }
      );
      return result;
    }
  }
});
