import store from './store';
import * as utils from '../utils/request';
import _ from 'lodash';

/**
 * @typedef {Object} Timeline
 * @property {string} id
 * @property {number} createdAt
 * @property {AccountSnippet} createdBy
 * @property {string} beneficiary
 * @property {number} date
 * @property {string} title
 * @property {string} body
 */

store.registerModule(
  'timeline',
  {
    namespaced: true,
    actions: {
      /**
       * @param ctx
       * @param {Object} opts
       * @param {string} opts.beneficiary
       * @returns {Timeline[]}
       */
      async get (ctx, opts) {
        opts = Object.assign({}, opts);
        if (!opts.beneficiary) throw new Error(`Beneficiary is required`);

        const searchParams = { ...opts };

        const timeline = await utils.request(
          `timeline`,
          {
            method: 'get',
            searchParams
          }
        );

        return timeline;
      },

      /**
       * @param ctx
       * @param {Object} opts
       * @param {string} opts.beneficiary
       * @param {string} opts.title
       * @param {string} opts.body
       */
      async create (ctx, opts) {
        opts = Object.assign({}, opts);
        if (!opts.beneficiary) throw new Error(`Beneficiary is required`);

        const body = { ...opts };

        await utils.request(
          `timeline`,
          {
            method: 'post',
            body
          }
        );
      },

      /**
       * @param ctx
       * @param {Object} opts
       * @param {string} opts.id
       * @param {string} opts.body
       * @param {string} opts.title
       */
      async update (ctx, opts) {
        opts = Object.assign({}, opts);
        if (!opts.id) throw new Error(`Id is required`);

        const body = { ..._.omit(opts, 'id') };

        await utils.request(
          `timeline/${opts.id}`,
          {
            method: 'put',
            body
          }
        );
      },

      /**
       * @param ctx
       * @param {Object} opts
       * @param {string} opts.id
       */
      async delete (ctx, opts) {
        opts = Object.assign({}, opts);
        if (!opts.id) throw new Error(`Id is required`);

        await utils.request(
          `timeline/${opts.id}`,
          {
            method: 'delete'
          }
        );
      }
    }
  }
);
