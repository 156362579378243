import Vue from 'vue';
import App from './App.vue';
import router from './router/routes';
import store from './store';
import vuetify from './plugins/vuetify';
import './mixins';
import '@mdi/font/css/materialdesignicons.css';
import './plugins/firebase';
import './plugins/cropper';
// import './plugins/vue-stripe-checkout';
import VueAnalytics from 'vue-analytics';
import CookieLaw from 'vue-cookie-law';
import { commafy, prettifyFirst } from './utils/format';

Vue.config.productionTip = false;
Vue.use(CookieLaw);
Vue.use(VueAnalytics, {
  id: 'UA-147646989-1',
  debug: {
    sendHitTask: process.env.VUE_APP_ENV === 'production'
  }
});
Vue.filter('commafy', commafy);
Vue.filter('prettifyFirst', prettifyFirst);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
