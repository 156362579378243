<template lang="pug">
  v-app#app
    v-content(:class="containerClasses")
      app-bar(v-if="showAppbar")
      router-view
      app-footer(v-if="showFooter")
    global-loader
    global-snackbar
</template>

<script>
import AppBar from './components/Appbar';
import AppFooter from './components/Footer';
import GlobalLoader from './components/GlobalLoader';
import GlobalSnackbar from './components/GlobalSnackbar';
export default {
  name: 'App',
  components: {
    AppBar,
    AppFooter,
    GlobalLoader,
    GlobalSnackbar
  },
  computed: {
    containerInset () {
      return this.$route.meta.containerInset;
    },
    containerClasses () {
      return {
        white: true,
        'pt-0': this.containerInset
      };
    },
    showFooter () {
      return this.$route.name !== 'signup' && this.$route.name !== 'forbidden';
    },
    showAppbar () {
      return this.$route.name !== 'forbidden';
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

#app{
  font-family: 'Poppins' !important;
}
.poppins{
  font-family: 'Poppins' !important;
}
.opensans{
  font-family: 'Open Sans', sans-serif !important;
}
.fontsize-12{
  font-size: 12px !important;
}
.fontsize-14{
  font-size: 14px !important;
}
.fontsize-16{
  font-size: 16px !important;
}
.fontsize-18{
  font-size: 18px !important;
}
.fontsize-20{
  font-size: 20px !important;
}
.fontsize-24{
  font-size: 24px !important;
}
.fontsize-28{
  font-size: 28px !important;
}
.fontsize-30{
  font-size: 30px !important;
}
.fontsize-32{
  font-size: 32px !important;
}
.fontsize-36{
  font-size: 36px !important;
}
.fontsize-40{
  font-size: 40px !important;
}
.fontsize-48{
  font-size: 48px !important;
}
.fontsize-60{
  font-size: 60px !important;
}
.panelLarge {
  padding-right: 200px !important;
  padding-left: 200px !important;
}
.panelMobile {
  padding-right: 28px !important;
  padding-left: 28px !important;
}
.titlePanel {
  height: 93vh;
}
</style>