import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/be-a-hero',
      name: 'beAHero',
      component: () =>
        import(/* webpackChunkName: "beAHero" */ '../views/BeAHero.vue')
    },
    {
      path: '/beneficiary/:id',
      name: 'beneficiaryById',
      props: true,
      component: () =>
        import(/* webpackChunkName: "beAHero" */ '../views/BeAHero.vue')
    },
    {
      path: '/benefactor/:uid',
      name: 'benefactor',
      props: true,
      component: () =>
        import(/* webpackChunkName: "beneficiary" */ '../views/Benefactor.vue')
    },
    {
      path: '/volunteer',
      name: 'volunteer',
      component: () =>
        import(/* webpackChunkName: "volunteer" */ '../views/Volunteer')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '../views/About')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () =>
        import(/* webpackChunkName: "signup" */ '../views/Signup')
    },
    // utils
    {
      path: '/notfound',
      name: 'notfound',
      component: () =>
        import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () =>
        import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
    },
    {
      path: '*',
      redirect: 'notfound'
    }
  ]
});
