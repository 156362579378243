<template lang="pug">
  v-card(
    :to="{ name: 'beneficiaryById', params: { id } }"
    :title="`Mighty Minds Profile ${name}`"
    @click="handleClick"
    height="100%"
    v-ripple="false"
  )
    v-img(
      :src="picURL" 
      :aspect-ratio="3/2"
      :alt="alt"
    )
    v-card-text.text-center.pa-2
      span.fontsize-18.accent--text.text-truncate Hi, I'm {{ name }}.
      p.opensans.fontsize-14.text-truncate.pt-1 {{ storyShort }}
      v-progress-linear(
        :indeterminate="loading"
        height="10"
        :value="fundingRaisedPercent"
        rounded
      )
      v-row.mx-0.fontsize-20.secondary--text.pb-2
        small #[small.font-weight-black.mr-2 {{ fundingRaisedPercent }}%] #[small Funded]
        v-spacer
        small #[small.font-weight-black.mr-2 ₱{{ fundingTogo | commafy}}] #[small to go]
  
</template>

<script>
import { round, truncate } from 'lodash';
import imageDefault from '@/assets/person-placeholder.png';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    beneficiary: {
      type: Object
    }
  },
  computed: {
    picURL () {
      if (this.loading) return imageDefault;
      const beneficiary = this.beneficiary || {};
      return beneficiary.picURL || imageDefault;
    },
    id () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.id || '';
    },
    name () {
      const beneficiary = this.beneficiary || {};
      const name = beneficiary.name || {};
      return name.firstName || '';
    },
    alt () {
      const beneficiary = this.beneficiary || {};
      const name = beneficiary.name || {};
      return name.firstName ? name.firstName.charAt(1) + name.lastName.charAt(1) : 'Scholar picture';
    },
    storyShort () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.storyShort || '';
    },
    funding () {
      const beneficiary = this.beneficiary || {};
      return beneficiary.activeFundingObject || {};
    },
    fundingTotal () {
      return this.funding.amount || 0;
    },
    fundingRaised () {
      return this.funding.raised || 0;
    },
    fundingRaisedPercent () {
      const fundingRaisePercent = round(((Number(this.fundingRaised) / Number(this.fundingTotal)) * 100)) || 0;
      return !fundingRaisePercent ? 0 : fundingRaisePercent >= 100 ? 100 : fundingRaisePercent;
    },
    fundingTogo () {
      const fundingTogo = this.fundingTotal - this.fundingRaised;
      return fundingTogo <= 0 ? 0 : fundingTogo;
    }
  },
  methods: {
    handleClick () {
      this.$ga.event({
        eventCategory: 'card',
        eventAction: 'click-beneficiary-card',
        eventLabel: 'beneficiary-card'
      });
    }
  },
  filters: {
    truncate (val, length = 40) {
      return truncate(val || '', { length });
    }
  }
};
</script>

<style scoped>
.plain {
  text-decoration: none;
}
</style>